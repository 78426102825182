<script lang="ts">
import { onMounted, defineComponent, ref } from "vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    onMounted(() => {})
    return {}
  },
})
</script>

<template>
  <main>
    <section class="page">
      <section class="questions">
        <div class="content">
          <h2 class="section-title">{{ $t("faq.title") }}</h2>
          <div class="columns">
            <div class="column">
              <h2 class="category-title">{{ $t("faq.model") }}</h2>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q1") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a1") }}</p>
                </div>
              </div>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q2") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a2") }}</p>
                </div>
              </div>
              <h2 class="category-title">{{ $t("faq.delivery") }}</h2>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q3") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a3") }}</p>
                </div>
              </div>

              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q4") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a4") }}</p>
                </div>
              </div>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q5") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a5") }}</p>
                </div>
              </div>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q6") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a6") }}</p>
                </div>
              </div>
            </div>
            <div class="column">
              <h2 class="category-title">{{ $t("faq.payment") }}</h2>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q7") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a7") }}</p>
                </div>
              </div>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q8") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a8") }}</p>
                </div>
              </div>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q9") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a9") }}</p>
                </div>
              </div>

              <h2 class="category-title">{{ $t("faq.metrics") }}</h2>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q10") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a10") }}</p>
                </div>
              </div>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q11") }}</div>
                <div class="answer">
                  <p v-safe-html="$t('faq.a11')"></p>
                </div>
              </div>
              <h2 class="category-title">{{ $t("faq.data") }}</h2>
              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q12") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a12") }}</p>
                </div>
              </div>

              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q13") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a13") }}</p>
                </div>
              </div>

              <h2 class="category-title">{{ $t("faq.privacy") }}</h2>

              <div
                class="item"
                @click="
                  (e) => e.target.parentElement.classList.toggle('is-open')
                "
              >
                <div class="question">{{ $t("faq.q14") }}</div>
                <div class="answer">
                  <p>{{ $t("faq.a14") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
  <Footer></Footer>
</template>

<style lang="scss" scoped>
@import "../css/home.scss";
</style>
