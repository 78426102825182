
import { onMounted, defineComponent, ref } from "vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    onMounted(() => {})
    return {}
  },
})
